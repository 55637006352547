<template>
    <div>
        <NuxtLink
            :to="link"
            target="_blank"
            class="group text-grind no-underline"
        >
            <div
                class="relative mb-2 aspect-square rounded-lg border border-grind-200 ring-inset ring-grind transition group-hover:border-grind group-hover:ring-2"
            >
                <StoryblokImage
                    :src="imgSrc || '/images/image-placeholder.svg'"
                    :alt="imgAlt ? imgAlt : 'Product Image'"
                    class="h-full w-full rounded-t-[10px] object-contain"
                    :class="{ 'm-auto p-4': !imgSrc }"
                    sizes="364px md:400px"
                />
            </div>
            <div class="text-base">
                <div class="font-bold">
                    {{ theme.display_name }}
                </div>
                <div>{{ theme.minimum }} from ${{ theme.price_starts_at }}</div>
            </div>
        </NuxtLink>
        <ThemeSwatches
            v-if="theme.colorways.length > 0 || theme.is_color_customizable"
            :colorways="theme.colorways"
            :is-color-customizable.camel="theme.is_color_customizable"
            @update-theme-image="changeThemeImage"
        />
    </div>
</template>

<script setup lang="ts">
import AlgoliaDesignLabTheme from '~/types/AlgoliaDesignLabTheme';
import { ref } from 'vue';
import ThemeSwatches from '~/components/page-building/design-lab-pick-your-product/components/ThemeSwatches.vue';

const props = defineProps<{
    theme: AlgoliaDesignLabTheme;
}>();

const imgSrc = ref(props.theme.image.src);
const imgAlt = ref(props.theme.image.alt);

const productId = ref(props.theme.product_id);
const slug = ref(props.theme.slug);

const link = computed<string>(() => {
    return (
        '/roastar-design-lab#product=' +
        productId.value +
        '&theme=' +
        slug.value
    );
});

const changeThemeImage = (index: number) => {
    const isCustomColorSwatch = index === 9999;
    if (isCustomColorSwatch) {
        handleCustomColorChange();
        return;
    }

    const productHasColorways = props.theme.colorways?.length > 0;
    if (productHasColorways) {
        imgSrc.value =
            props.theme.colorways[index]['parent_image']['src'] ??
            '/images/image-placeholder.svg';
        imgAlt.value =
            props.theme.colorways[index]['parent_image']['alt'] ??
            'Product Image';

        if (props.theme.colorways?.[index]?.['product_id']) {
            productId.value = props.theme.colorways[index]['product_id'];
        }

        if (props.theme.colorways?.[index]?.['slug']) {
            slug.value = props.theme.colorways[index]['slug'];
        }
    }
};

const handleCustomColorChange = () => {
    if (props.theme.is_color_customizable) {
        imgSrc.value =
            props.theme.custom_color_image['src'] ?? chooseYourOwnSources;
        imgAlt.value = props.theme.custom_color_image['alt'] ?? 'Product Image';
    }
};
</script>
