<template>
    <FilterExpand
        :title="title"
        :count="items.length"
        :expanded-default="expandedDefault"
        :mobile-menu-open="mobileMenuOpen"
        :data-cy="'filter-expand-' + slugify(title)"
    >
        <ul>
            <li
                v-for="item in sortedItems"
                :key="item.value"
                class="cursor-pointer rounded p-2 hover:bg-brew hover:text-white"
                :class="item.isRefined ? 'text-grind' : 'text-grind'"
                :data-cy="
                    'filter-checkbox-' +
                    slugify(title) +
                    '-' +
                    slugify(item.value)
                "
                @click="$emit('toggle', item.value)"
            >
                <div class="flex w-full items-center">
                    <input
                        :key="item.value"
                        :name="getUniqueInputName(item.value)"
                        :checked="item.isRefined"
                        :aria-label="item.value"
                        type="checkbox"
                        class="cursor-pointer checked:bg-grind"
                    />
                    <div
                        class="cursor-pointer"
                        :class="{
                            'font-bold': item.isRefined,
                        }"
                    >
                        {{ item.label ?? item.value }}
                    </div>
                </div>
            </li>
        </ul>
    </FilterExpand>
</template>

<script setup lang="ts">
import FilterExpand from '~/components/FilterExpand.vue';
const { portalUrl } = useRuntimeConfig().public;

const props = defineProps({
    title: {
        type: String,
        required: true,
    },
    expandedDefault: {
        type: Boolean,
        default: false,
    },
    mobileMenuOpen: {
        type: Boolean,
        default: false,
    },
    items: {
        type: Array,
        default: () => [],
    },
});

const sortedItems = computed(() => {
    const items = [...props.items];

    if (props.title == 'Product Type') {
        items.sort(sortProductTypes);
    } else if (props.title == 'Theme') {
        items.sort(sortThemes);
    } else if (props.title == 'Capacity') {
        items.sort(sortCapacity);
    } else if (props.title == 'Minimum Order Quantity') {
        items.sort(sortMinimums);
    }

    return items;
});

const productTypeOrder = ['Gusset Bag', 'Flat Bottom Bag', 'Tin Can'];

const sortProductTypes = (a: any, b: any) => {
    if (productTypeOrder.indexOf(a.label) < productTypeOrder.indexOf(b.label)) {
        return -1;
    } else if (
        productTypeOrder.indexOf(a.label) > productTypeOrder.indexOf(b.label)
    ) {
        return 1;
    }
    return 0;
};

const { data: themesOrder } = await $fetch(
    `${portalUrl}/api/design-lab/theme-groups`,
);

const sortThemes = (a: any, b: any) => {
    if (themesOrder.indexOf(a.label) < themesOrder.indexOf(b.label)) {
        return -1;
    } else if (themesOrder.indexOf(a.label) > themesOrder.indexOf(b.label)) {
        return 1;
    }
    return 0;
};

const capacityOrder = [
    'Samples (2-6oz)',
    'Small fill (6-10oz)',
    'Standard fill (10-12 oz)',
    'Large Fill (12 oz - 5 lbs)',
];

const sortCapacity = (a: any, b: any) => {
    if (capacityOrder.indexOf(a.label) < capacityOrder.indexOf(b.label)) {
        return -1;
    } else if (
        capacityOrder.indexOf(a.label) > capacityOrder.indexOf(b.label)
    ) {
        return 1;
    }
    return 0;
};

const minimumsOrder = ['1 or more', '25 or more', '100 or more'];

const sortMinimums = (a: any, b: any) => {
    if (minimumsOrder.indexOf(a.label) < minimumsOrder.indexOf(b.label)) {
        return -1;
    } else if (
        minimumsOrder.indexOf(a.label) > minimumsOrder.indexOf(b.label)
    ) {
        return 1;
    }
    return 0;
};

defineEmits(['toggle']);

const getUniqueInputName = (value: string) => {
    let id = `${props.title}-${value}`;
    if (props.mobileMenuOpen) {
        id += '-mobile';
    }
    return id;
};
</script>
